import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Col, Row, Button } from 'react-bootstrap'
import Footer from './../components/footer'
import Container from 'react-bootstrap/Container'
import './../components/media.css'
import './../components/common.css'
import { bdstatistics, mqStatistics } from '../components/census'

const LoginPage = () => {
  React.useEffect(() => {
    bdstatistics()
    mqStatistics()
  }, [])

  const handleOpenForm = () => {
    // Replace 'YOUR_FORM_URL' with the actual URL of your online form
    window.open('https://r8ja3mlg7i.feishu.cn/share/base/form/shrcn6Ns5Vk4fgiEanpunuN71Ve', '_blank')
  }

  return (
    <>
      <div className="home-bg contact-box" style={{ background: 'none', height: '7.5rem' }}>
        <Layout>
          <SEO title="物联网平台_数采平台_工业物联网 ——低代码搭建IOT平台" />
        </Layout>
      </div> 
      <Container style={{ paddingTop: '2.5rem', paddingBottom: '2.5rem' }}>
        <Row className="align-items-center">
          <Col md={6} className="mb-4 mb-md-0">
            <div className="text-center text-md-left">
              <h2 className="mb-4" style={{ color: '#3f51b5', fontWeight: 'bold' }}>选择AIRIOT，轻松搭建物联网平台</h2>
              <p className="mb-4">请填写信息，我们的销售团队将尽快与您联系。</p>
              <Button 
                onClick={handleOpenForm} 
                variant="primary" 
                size="lg"
                style={{
                  backgroundColor: '#3f51b5',
                  border: 'none',
                  padding: '15px 30px',
                  fontSize: '1.2rem',
                  fontWeight: 'bold',
                  borderRadius: '30px',
                  boxShadow: '0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)',
                  transition: 'all 0.15s ease'
                }}
              >
                填写联系表单
              </Button>
            </div>
          </Col>
          {/* <Col md={6}>
            <div className="select-bg" style={{ 
              backgroundImage: 'url("path-to-your-image.jpg")', 
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              height: '300px',
              borderRadius: '10px'
            }}></div>
          </Col> */}
        </Row>
      </Container>
      <Footer style={{ marginTop: '0' }}></Footer>
    </>
  )  
}

export default LoginPage